import React, { useState, useRef, useEffect } from 'react';
import { motion } from "framer-motion";
import { FileUpload } from './ui/file-upload';
import LampDemo from './ui/lamp';
import ImageGrid from './ImageGrid';
import Modal from './ui/Modal';
import { processImage } from '../services/replicateService';
import { useNavigate, Link } from 'react-router-dom';
import { Card } from "./ui/Card";
import { FlipWords } from './FlipWords';
import { AuroraBackground } from './ui/aurora-background';
import { db } from '../firebase';
import { collection, addDoc, updateDoc, doc, getFirestore, FieldValue, getDoc } from 'firebase/firestore';
import { storage } from '../firebase';
import { ref, uploadBytes, uploadString, getDownloadURL } from 'firebase/storage';
import { countryPhotoTypes, photoRequirementsDetailed } from '../data/photoOptions_v2';
import PassportImageScroll from './PassportImageScroll';
import FAQSection from './FAQSection';
import CustomerReviews from '../components/CustomerReviews';
const HomePage = () => {
  const navigate = useNavigate();
  const [selectedImage, setSelectedImage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const fileInputRef = useRef(null);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [orderId, setOrderId] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(Object.keys(countryPhotoTypes)[0]);
  const [selectedPhotoType, setSelectedPhotoType] = useState(countryPhotoTypes[Object.keys(countryPhotoTypes)[0]][0]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.pageYOffset;
      if (scrollTop > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      
      // Check file size (20MB = 20 * 1024 * 1024 bytes)
      if (file.size > 20 * 1024 * 1024) {
        alert("File size exceeds 20MB. Please choose a smaller file.");
        return;
      }

      // Check file type
      const acceptedFormats = ['image/jpeg', 'image/png', 'image/tiff', 'image/bmp', 'image/webp', 'image/jpg'];
      if (!acceptedFormats.includes(file.type)) {
        alert("Invalid file format. Please choose a JPEG, JPG,PNG, TIFF, BMP or WEBP image.");
        return;
      }

      setUploadedFile(file);
      setIsModalOpen(true);

      // Calculate aspect ratio
      const img = new Image();
      img.onload = async () => {
        setAspectRatio(img.width / img.height);
        
        // Create order ID and save to Firestore
        try {
          const docRef = await addDoc(collection(db, "orders"), {
            createdAt: new Date(),
            status: "uploaded"
          });
          setOrderId(docRef.id);

          // Save input photo to Firebase Storage
          const storageRef = ref(storage, `orders/${docRef.id}/input.jpg`);
          await uploadBytes(storageRef, file);
        } catch (error) {
          console.error("Error creating order:", error);
        }
      };
      img.src = URL.createObjectURL(file);
    }
  };

  const resetUpload = () => {
    setUploadedFile(null);
    setIsModalOpen(false);
    setSelectedCountry(Object.keys(countryPhotoTypes)[0]);
    setSelectedPhotoType(countryPhotoTypes[Object.keys(countryPhotoTypes)[0]][0]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleModalClose = () => {
    resetUpload();
  };

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedPhotoType(countryPhotoTypes[country][0]);
  };

  const handleProcessImage = async () => {
    if (uploadedFile) {
      try {
        setIsLoading(true);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64String = reader.result;
          const selectedKey = `${selectedCountry}-${selectedPhotoType}`;
          const photoRequirements = photoRequirementsDetailed[selectedKey];
          try {
            const output = await processImage(
              base64String,
              photoRequirements.ImageSize,
              photoRequirements.SheetSize,
              photoRequirements.dpi,
              photoRequirements.digital_dims,
              photoRequirements.background_color
            );
            //console.log("Processed image:", output);

            const processedImageUrls = [];
            // Fetch and upload processed images to Firebase Storage
            for (let i = 0; i < output.length; i++) {
              const response = await fetch(output[i]);
              const blob = await response.blob();
              const storageRef = ref(storage, `orders/${orderId}/processed_${i}.jpg`);
              await uploadBytes(storageRef, blob, { 
                customMetadata: { origin: window.location.origin },
                headers: { 'Origin': window.location.origin }
              });
              const downloadUrl = await getDownloadURL(storageRef);
              processedImageUrls.push(downloadUrl);
            }

            // Update order status and processedImages in Firestore
            const orderRef = doc(db, "orders", orderId);
            const orderDoc = await getDoc(orderRef);
            const currentStatus = orderDoc.data().status || '';
            await updateDoc(orderRef, {
              status: currentStatus ? `${currentStatus}, processed` : 'processed',
              processedImages: processedImageUrls
            });

            setIsLoading(false);
            setIsModalOpen(false);
            //console.log("HomePage - passportType before navigation:", selectedKey);
            navigate('/preview', { 
              state: { 
                processedImages: output, // Pass the direct output URLs
                orderId: orderId, 
                passportType: selectedKey
              } 
            });
          } catch (error) {
            setIsLoading(false);
            alert(`Error processing image: ${error.message}`);
          }
        };
        reader.readAsDataURL(uploadedFile);
      } catch (error) {
        console.error("Error processing image:", error);
        setIsLoading(false);
        alert(`Error processing image: ${error.message}`);
      }
    }
  };

  const passportImage = imagePath => {
    return (
      <img
        src={imagePath}
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/images/passport_imgs/Generic.webp";
        }}
        alt="Passport"
        className="max-h-[100%] w-auto object-contain"
      />
    );
  };

  return (
    <AuroraBackground>
      {/* Header - moved outside the main content div to be fixed on top */}
      <header className={`fixed top-0 left-0 right-0 z-50 flex justify-between items-center p-4 transition-colors duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'}`}>
        <Link to="/" className="flex items-center">
          <img src="/passport_logo.png" alt="Passport Logo" className="h-10 w-auto" />
          <span className={`text-sm hover:text-cyan-400 transition-colors duration-300 ml-4 hidden md:inline ${isScrolled ? 'text-slate-950' : 'text-slate-950 dark:text-white'}`}>
            passport-visa-photo.com
          </span>
        </Link>
        <div className="flex justify-end items-center">
          <button
            onClick={handleUploadClick}
            className="p-[2px] sm:p-[3px] relative rounded-lg group"
          >
            <div className="absolute inset-0 bg-blue-600 rounded-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300" />
            <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-green-800 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
            <div className="px-3 py-2 sm:px-4 sm:py-2 md:px-6 md:py-3 bg-blue-600 rounded-lg relative text-sm sm:text-base md:text-xl lg:text-2xl text-white group-hover:bg-green-800 transition-colors duration-300 font-bold">
              Upload Photo
            </div>
          </button>
        </div>
      </header>

      <div className="min-h-screen flex flex-col relative">
        {/* Main content - add padding-top to account for fixed header */}
        <main className="flex-grow pt-10"> {/* Added padding-top */}
          {/* Title section */}
          <div className="relative flex flex-col gap-4 items-center justify-center px-4 mt-10">
            <h1 className="text-slate-950 dark:text-white py-2 text-center text-2xl sm:text-2xl md:text-4xl lg:text-4xl font-bold tracking-tight relative z-20 w-full">
              <span className="inline-flex flex-nowrap whitespace-nowrap items-center">
                <span className="inline-block">Biometric Photo for</span>
                <span className="inline-block w-2 md:w-4"></span>
                <span className="inline-block relative w-[37%] md:w-[37%] overflow-hidden">
                  <span className="absolute inset-0 bg-indigo-300 rounded-lg"></span>
                  <FlipWords
                    words={[
                      { text: "Passport", duration: 4000 },
                      { text: "Visa", duration: 1000 },
                      { text: "ID", duration: 1000 }
                    ]}
                    className="relative z-10 px-1 py-1 sm:py-2 inline-block w-full text-left"
                  />
                </span>
              </span>
            </h1>
          </div>

          {/* Main sections */}
          <div className="flex flex-col md:flex-row mt-4 sm:mt-4 md:mt-16">
            {/* Left side */}
            <div className="w-full md:w-1/2 px-10 pt-3 pb-0 md:p-8 flex flex-col items-center justify-start md:justify-center md:h-[60vh] tall:h-auto md:pl-[5%]">
              <div className="text-left w-full mb-8 pl-[0%] sm:pl-[5%] md:pl-[5%] lg:pl-[15%]">
                <ul className="space-y-1 md:space-y-2">
                  {[
                    "Fast&nbsp;&nbsp;<span class='inline-flex items-center justify-center bg-green-500 text-white rounded-full w-8 h-8 md:w-9 md:h-9 lg:w-10 lg:h-10 mr-4 text-2xl sm:text-2xl md:text-2xl lg:text-2xl  align-middle'> 5s</span>",
                    "Free Digital",
                    "Doorstep Delivery",
                    "All Nationalities"
                  ].map((text, index) => (
                    <li key={index} className="flex items-center">
                      <span className="flex-shrink-0 w-8 h-8 bg-white rounded-full flex items-center justify-center mr-4">
                        <svg className="w-6 h-6 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="M5 13l4 4L19 7"></path>
                        </svg>
                      </span>
                      <span 
                        className="text-slate-950 dark:text-white text-xl sm:text-xl md:text-xl lg:text-xl font-bold tracking-tight flex items-center"
                        dangerouslySetInnerHTML={{ __html: text }}
                      />
                    </li>
                  ))}
                </ul>
              </div>
              <div className="hidden md:block mb-4 w-full mt-[0%]">
                <FileUpload onChange={handleFileChange} />
              </div>
              <input
                type="file"
                ref={fileInputRef}
                onChange={(e) => handleFileChange(e.target.files)}
                className="hidden"
              />
              <button
                onClick={handleUploadClick}
                className="p-[3px] relative rounded-lg group"
              >
                <div className="absolute inset-0 bg-blue-600 rounded-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300" />
                <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-green-800 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                <div className="px-6 py-3 bg-blue-600 rounded-lg relative text-2xl text-white group-hover:bg-green-800 transition-colors duration-300 w-60 font-bold">
                  Upload Photo
                </div>
              </button>
            </div>
            
            {/* Right side */}
            <div className="w-full md:w-1/2 pt-12 px-6 md:px-12 pb-12 pb-10 md:pb-2 flex flex-col items-center justify-start md:justify-end tall:justify-center tall:h-[45vh] h-[45vh] md:h-[60vh]">
              <div className="w-full md:w-[80%] items-center aspect-[8/2]">
                <LampDemo />
              </div>
              <div className="w-full md:w-[80%] items-center aspect-[2/1]">
                <ImageGrid selectedImage={selectedImage} onImageClick={setSelectedImage} />
              </div>
              <p className="w-full md:w-[80%] text-gray-500 text-center mt-4 text-sm md:text-base">
                Perfect background removal. Auto crop to country specifications. Free download.
              </p>
            </div>
          </div>

          {/* Passport Image Scroll Section */}
          <div className="w-full px-6 md:px-12 mt-6 md:mt-12">
            <PassportImageScroll />
          </div>

          {/* Cards section */}
          <div className="w-full px-4 md:px-12 mt-8 md:mt-12 mb-16">
            <h2 className="text-slate-950 dark:text-white text-3xl sm:text-3xl md:text-4xl lg:text-4xl font-bold tracking-tight mb-8 text-left">
              How it works:
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-4 tall:grid-cols-1 gap-6 md:gap-4 justify-items-center tall:justify-items-start">
              {[
                { 
                  title: "Take or upload a photo", 
                  content: "Even lighting, with no shadows. Face camera, with neutral expression. No glasses, hair shouldn't cover the face.",
                  backgroundImage: "/images/upload-photo.jpg"
                },
                { 
                  title: "Tool processes photo", 
                  content: "Perfect background removal. Precise crop to comply with guidelines.",
                  backgroundImage: "/images/verify-photo.jpg"
                },
                { 
                  title: "Submit digital photo", 
                  content: "Free download of digital photo. Option to purchase prints.",
                  backgroundImage: "/images/submit-photo.jpg"
                },
                { 
                  title: "Physical copies delivered", 
                  content: "Additional expert review. Receive high-quality physical copies.",
                  backgroundImage: "/images/deliver-photo.jpg"
                },
              ].map((card, index) => (
                <div
                  key={index}
                  className="w-full max-w-sm"
                >
                  <Card {...card} />
                </div>
              ))}
            </div>
          </div>
        </main>

        {/* Modal */}
        <Modal isOpen={isModalOpen} onClose={handleModalClose}>
          {uploadedFile && (
            <div className="flex flex-col md:flex-row tall:flex-col text-slate-800">
              <div className="w-full md:w-1/2 tall:w-full flex flex-col bg-blue-50 dark:bg-blue-900 rounded-lg p-4 m-2 h-auto tall:h-auto h-[60vh] left-container">
                <div className="flex flex-col items-center justify-start pt-4 ">
                  <select
                    className="w-full p-2 border-2 border-blue-600 rounded bg-white text-slate-800 text-2xl sm:text-2xl md:text-3xl lg:text-3xl appearance-none mb-2"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    {Object.keys(countryPhotoTypes).map((country) => (
                      <option key={country} value={country} className="text-2xl sm:text-2xl md:text-3xl lg:text-3xl">{country}</option>
                    ))}
                  </select>
                  <div className="w-full mb-1 md:mb-4">
                    {countryPhotoTypes[selectedCountry].slice(0, 4).map((photoType, index) => (
                      <div key={photoType} className="flex items-center mb-3">
                        <input
                          type="radio"
                          id={`photoType-${index}`}
                          name="photoType"
                          value={photoType}
                          checked={selectedPhotoType === photoType}
                          onChange={() => setSelectedPhotoType(photoType)}
                          className="mr-1 md:mr-2 w-6 h-6 md:w-8 md:h-8"
                        />
                        <label htmlFor={`photoType-${index}`} className="text-xl sm:text-xl md:text-xl lg:text-2xl">{photoType}</label>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handleProcessImage}
                    className="w-full p-[3px] relative rounded-lg group mb-4"
                    disabled={isLoading}
                  >
                    <div className="absolute inset-0 bg-blue-600 rounded-lg opacity-100 group-hover:opacity-0 transition-opacity duration-300" />
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-green-800 rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                    <div className="px-6 py-3 bg-blue-600 rounded-lg relative text-2xl text-white group-hover:bg-green-800 transition-colors duration-300 w-full font-bold flex items-center justify-center">
                      {isLoading ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-3 h-6 w-6 md:h-8 md:w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          Processing...
                        </>
                      ) : (
                        'Process Image'
                      )}
                    </div>
                  </button>
                  <div className="flex items-center justify-center text-gray-500 text-xs md:text-sm mb-4">
                    <img src="images/passport_imgs/icao.webp" alt="ICAO" className="h-8 w-auto mr-2" />
                    <span>Our photos are compliant with ICAO standards</span>
                  </div>
                </div>
                <div className="flex items-center justify-start p-4 border-t h-[30%] md:h-[25%] bg-white rounded-lg">
                  <div className="h-full flex">
                    <div className="w-[35%] md:w-[30%] pr-2 flex items-center justify-center">
                      {passportImage(photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].image_path_passport)}
                    </div>  
                    <div className="w-[65%] md:w-[70%] pl-2 flex flex-col justify-center text-sm sm:text-sm md:text-base lg:text-base">
                      <p>Physical: <strong>{photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].ImageSize}</strong></p>
                      <p>Resolution: <strong>{photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].dpi}</strong></p>
                      <p>Digital: <strong>{photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].digital_dims}</strong></p>
                      <p>Background: <strong>{photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].background_color_modal}</strong></p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 tall:w-full items-center justify-center flex flex-col bg-blue-50 dark:bg-blue-900 rounded-lg p-4 m-2 right-container h-[60vh] md:h-[60vh]">
                <div className="flex-1 flex flex-col items-center justify-center h-[50%] mb-4">
                  <h3 className="text-sm md:text-xl font-bold text-center mb-2">
                    <span className="bg-blue-600 text-white py-1 px-3 rounded-lg">
                      Before
                    </span>
                  </h3>
                  <div className="relative h-[90%] bg-white rounded-lg">
                    <img
                      src={photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].image_path_org}
                      alt="Before"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-center justify-center h-[50%]">
                  <h3 className="text-sm md:text-xl font-bold text-center mb-2">
                    <span className="bg-blue-600 text-white py-1 px-3 rounded-lg">
                      After
                    </span>
                  </h3>
                  <div className="relative h-[90%] bg-white rounded-lg">
                    <img
                      src={photoRequirementsDetailed[`${selectedCountry}-${selectedPhotoType}`].image_path_p_guidelines}
                      alt="After"
                      className="w-full h-full object-contain"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>

        {/* FAQ Section */}
        <FAQSection />

        {/* Customer Reviews */}
        <div className="w-full max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <CustomerReviews />
        </div>

        {/* Footer */}
        <footer className="bg-gradient-to-b from-white to-blue-100 dark:from-gray-900 dark:to-gray-800 text-slate-950 dark:text-white py-8">
          <div className="container mx-auto px-4 lg:w-[70%]">
            <div className="flex flex-wrap justify-between items-start">
              <div className="w-full md:w-1/2 mb-4 md:mb-0">
                <h3 className="font-bold mb-2">Contact Us</h3>
                <div className="flex items-center">
                  <img src="/passport_logo.png" alt="Passport Logo" className="h-10 w-auto mr-2" />
                  <a href="mailto:support@passport-visa-photo.com" className="text-blue-600 dark:text-blue-400 hover:underline">
                    support@passport-visa-photo.com
                  </a>
                </div>
              </div>
              <div className="w-full md:w-1/2">
                <ul className="space-y-2">
                  <li>
                    <span className="cursor-pointer hover:underline">Terms and Conditions</span>
                  </li>
                  <li>
                    <span className="cursor-pointer hover:underline">Privacy Policy</span>
                  </li>
                  <li>
                    <span className="cursor-pointer hover:underline">About Us</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </AuroraBackground>
  );
}

export default HomePage;