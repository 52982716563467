import { functions, httpsCallable } from '../firebase';

export const processImage = async (imageFile, imageSize, sheetSize, dpi = 310, digitalDims = "600x600", backgroundColor = "255,255,255") => {
  try {
    //console.log("Sending request with imageSize:", imageSize, "sheetSize:", sheetSize, "dpi:", dpi, "digitalDims:", digitalDims, "backgroundColor:", backgroundColor);
    //console.log("imageFile length:", imageFile.length);

    const response = await fetch('/api/processImage', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imageFile: imageFile.split(",")[1],
        imageSize,
        sheetSize,
        dpi,
        digitalDims,
        backgroundColor,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    //console.log("Server response:", data);

    if (data.status === "succeeded") {
      return data.output;
    } else {
      throw new Error(`Processing failed: ${data.error || 'Unknown error'}`);
    }
  } catch (error) {
    console.error("Error processing image:", error);
    throw error;
  }
};