import { initializeApp } from 'firebase/app';
import { getFunctions, httpsCallable as firebaseHttpsCallable } from 'firebase/functions';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyBfpDrTv3nEhr_YKAq7kTuWwqsJQhY_8Ak",
    authDomain: "passport-photo-8c920.firebaseapp.com",
    projectId: "passport-photo-8c920",
    storageBucket: "passport-photo-8c920.appspot.com",
    messagingSenderId: "151482285855",
    appId: "1:151482285855:web:6322ae7b223b46f33f3a2a",
    measurementId: "G-BFVNPH8E98"
};

const app = initializeApp(firebaseConfig);
const functions = getFunctions(app, "us-central1");
const db = getFirestore(app);
const storage = getStorage(app);

export { app, functions, db, storage };
export const httpsCallable = firebaseHttpsCallable;

export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

// console.log('Stripe Public Key:', process.env.REACT_APP_STRIPE_PUBLIC_KEY);
