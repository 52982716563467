import React, { useEffect, useState, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";

export const FlipWords = ({ words, className }) => {
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [padding, setPadding] = useState(0);
  const wordRef = useRef(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, words[currentWordIndex].duration);

    return () => clearInterval(interval);
  }, [words, currentWordIndex]);

  useEffect(() => {
    if (wordRef.current) {
      const maxWidth = Math.max(...words.map(word => {
        wordRef.current.textContent = word.text;
        return wordRef.current.offsetWidth;
      }));
      setPadding(maxWidth);
    }
  }, [words]);

  return (
    <div className={`w-full h-full ${className}`}>
      <AnimatePresence mode="wait">
        <motion.div
          key={words[currentWordIndex].text}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.3 }}
          className="w-full h-full flex items-center"
          style={{ minWidth: `${padding}px` }}
        >
          {words[currentWordIndex].text}
        </motion.div>
      </AnimatePresence>
      <span ref={wordRef} className="absolute opacity-0 pointer-events-none" />
    </div>
  );
};