import React, { useEffect, useState, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

// List of passport images
const passportImages = [
  '/images/passport_imgs/Australia.webp',
  '/images/passport_imgs/Canada.webp',
  '/images/passport_imgs/China.webp',
  '/images/passport_imgs/France.webp',
  '/images/passport_imgs/Germany.webp',
  '/images/passport_imgs/India.webp',
  '/images/passport_imgs/Poland.webp',
  '/images/passport_imgs/Singapore.webp',
  '/images/passport_imgs/UnitedKingdom.webp',
  '/images/passport_imgs/UnitedStates.webp',
  '/images/passport_imgs/icao.webp',
  // Add or remove images as needed
];

const PassportImageScroll = () => {
  const containerRef = useRef(null);
  const controls = useAnimation();
  const [containerWidth, setContainerWidth] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      setContainerWidth(containerRef.current.offsetWidth);
    }
  }, []);

  useEffect(() => {
    if (containerWidth > 0) {
      controls.start({
        x: [-containerWidth, 0],
        transition: {
          x: {
            repeat: Infinity,
            repeatType: "loop",
            duration: 30,
            ease: "linear",
          },
        },
      });
    }
  }, [containerWidth, controls]);

  const getImagestoFillWidth = () => {
    if (!containerWidth) return [];
    const imageWidth = 80; // Approximate width of each image
    const imagesNeeded = Math.ceil(containerWidth / imageWidth) * 2; // Double it to ensure coverage
    return Array(imagesNeeded).fill().map((_, i) => passportImages[i % passportImages.length]);
  };

  return (
    <div className="w-full overflow-hidden bg-white-100 py-6" ref={containerRef}>
      <motion.div
        className="flex"
        animate={controls}
        initial={{ x: 0 }}
      >
        {getImagestoFillWidth().map((src, index) => (
          <img
            key={index}
            src={src}
            alt={`Passport ${index + 1}`}
            className="h-20 md:h-24 w-auto mx-4 opacity-40 "
          />
        ))}
      </motion.div>
    </div>
  );
};

export default PassportImageScroll;
