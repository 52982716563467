"use client";
import { cn } from "../../lib/utils";
import { useState, useEffect } from 'react';
import { motion } from "framer-motion";

const BackgroundGradient = ({ children, className, containerClassName, animate = true }) => {
  const variants = {
    initial: {
      backgroundPosition: "0 10%",
    },
    animate: {
      backgroundPosition: ["0, 30%", "100% 30%", "0 30%"],
    },
  };
  return (
    <div className={cn("relative group overflow-hidden rounded-lg", containerClassName)}>
      <motion.div
        variants={animate ? variants : undefined}
        initial={animate ? "initial" : undefined}
        animate={animate ? "animate" : undefined}
        transition={
          animate
            ? {
                duration: 5,
                repeat: Infinity,
                repeatType: "reverse",
              }
            : undefined
        }
        style={{
          backgroundSize: animate ? "400% 400%" : undefined,
        }}
        className={cn(
          "absolute inset-0 z-[1] opacity-100 group-hover:opacity-100 blur-[6px] transition duration-500",
          "bg-[radial-gradient(circle_farthest-side_at_0_100%,#2563eb,transparent),radial-gradient(circle_farthest-side_at_100%_0,#2563eb,transparent),radial-gradient(circle_farthest-side_at_100%_100%,#2563eb,transparent),radial-gradient(circle_farthest-side_at_0_0,#2563eb,#141316)]"
        )} 
      />
      <div className={cn("relative z-10 bg-white/80 rounded-lg overflow-hidden", className)}>{children}</div>
    </div>
  );
};

export function Card({ title, content, backgroundImage }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(backgroundImage);
        const blob = await response.blob();
        const reader = new FileReader();
        reader.onloadend = () => setImageUrl(reader.result);
        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [backgroundImage]);

  // Split content into bullet points
  const bulletPoints = content.split('.').filter(point => point.trim() !== '').slice(0, 4);

  return (
    <BackgroundGradient className="w-full p-[3px]">
      <div className="w-full group/card bg-white/80 rounded-lg overflow-hidden">
        <div
          className={cn(
            "cursor-pointer overflow-hidden relative card rounded-lg shadow-xl flex flex-col justify-between transition-all duration-300",
            "aspect-[5/2] md:aspect-[4/2] tall:aspect-[5/2]"
          )}
        >
          {/* Blurred background */}
          <div 
            className="absolute inset-0 bg-cover bg-center"
            style={{ 
              backgroundImage: `url(${imageUrl})`,
              filter: 'blur(10px)',
              transform: 'scale(1.1)'
            }}
          ></div>
          
          {/* Main image */}
          <div 
            className="absolute inset-0 bg-contain bg-center bg-no-repeat z-10"
            style={{ 
              backgroundImage: `url(${imageUrl})`
            }}
          ></div>
          
          {/* Blue overlay matching upload photo button */}
          <div className="absolute inset-0 bg-indigo-300 opacity-80 group-hover/card:opacity-100 transition-opacity duration-300 z-20"></div>
          
          {/* Content */}
          <div className="text-content relative z-30 flex flex-col h-full justify-start p-4">
            <h3 className="font-bold text-xl md:text-lg tall:text-xl text-slate-950 mb-2 transition-colors duration-300">
              {title}
            </h3>
            <ul className="mt-1 md:mt-1 list-disc list-inside">
              {bulletPoints.map((point, index) => (
                <li key={index} className="text-base md:text-sm tall:text-base text-slate-950 mb-1 text-left">
                  {point.trim()}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </BackgroundGradient>
  );
}