const faqs = [
  {
    question: "Can I take my own photograph?",
    answer: "Yes, you can take your own photograh. Please ensure you're looking directly at the center of the screen, so that you're eyes are level, and capture a waist up selfie. The service will get your photo ready for paper and digital applications, making sure it meets all the official requirements."
  },
  {
    question: "What is the cost and is there a free tier?",
    answer: "Yes, there is a free tier. We offer the digital copy and 5x7 inch print sheet (6 photos) at no cost - it's free to download. The sheet can be printed at nearest Walgrees, CVS or Walmart for a nominal price (<$1). We also encourage you to include expert human vertication for $4.99, which significantly reduces chances of rejection."
  },
  {
    question: "Do you provide printed photos?",
    answer: "Yes, we do ship printed photos for an all inclusive price of $14.99 (6 photos). We use USPS First Class Mail for shipping, which takes 1-5 business days."
  },
  {
    question: "What is your refund policy?",
    answer: "If any of purchased photos are rejected, we will refund the entire amount paid to us. We will also replace the photo with a new one at no cost."
  },
  {
    question: "Who can I contact if I have any questions before or after the purchase?",
    answer: "Please direct any questions or feedback to support@passport-visa-photo.com. We respond within 24 hours."
  }
];

export default faqs;
