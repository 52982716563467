import React from 'react';

const reviews = [
  {
    name: "Sarah J.",
    text: "This website is a game-changer! I dreaded getting my passport photo taken, but uploading my own picture and having it perfectly cropped was so easy. The background removal was flawless, and the prints arrived quickly. The expert review gave me peace of mind. Highly recommend!"
  },
  {
    name: "Michael T.",
    text: "Absolutely brilliant service! I was skeptical at first, but the results exceeded my expectations. The photo met all the requirements, and the printing quality was superb. The expert review option is worth every penny - no more worries about rejected photos. This is the future of passport pictures!"
  },
  {
    name: "Emily R.",
    text: "As someone who hates getting their picture taken, this website was a lifesaver. I could take my time to get a photo I liked, and the automatic cropping and background removal worked perfectly. The prints arrived in just a few days, and they look incredibly professional. I'll never go back to a photo booth again!"
  },
  {
    name: "David L.",
    text: "What a time-saver! I needed passport photos for my whole family, and this website made it so simple. We took the photos at home, uploaded them, and had them delivered right to our door. The expert review option gave us extra confidence. The quality is excellent, and it was much cheaper than going to a studio. 10/10 would use again!"
  },
];

const CustomerReviews = () => {
  return (
    <div className="py-12">
      <h2 className="text-2xl sm:text-3xl font-bold text-center mb-8">What Our Customers Say</h2>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {reviews.map((review, index) => (
          <div key={index} className="bg-white dark:bg-gray-800 rounded-lg shadow-md p-6">
            <p className="text-lg font-semibold mb-2">{review.name}</p>
            <p className="text-sm italic">"{review.text}"</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerReviews;
