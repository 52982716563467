import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
import { collection, query, where, getDocs, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { photoRequirementsDetailed } from '../data/photoOptions_v2';
import { getFirestore } from 'firebase/firestore';

const SuccessPage = () => {
  const [processedImages, setProcessedImages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [passportType, setPassportType] = useState(null);
  const [orderId, setOrderId] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    //console.log("Location state in SuccessPage:", location.state);

    const fetchOrderDetails = async () => {
      try {
        let fetchedOrderId, passportType;

        // Check if orderId and passportType are in URL parameters (from Stripe checkout)
        const urlParams = new URLSearchParams(window.location.search);
        fetchedOrderId = urlParams.get('order_id');
        passportType = urlParams.get('passport_type');

        // If not in URL, check location state (from free digital option)
        if (!fetchedOrderId && location.state) {
          fetchedOrderId = location.state.orderId;
          passportType = location.state.passportType;
        }

        //console.log("OrderId from url:", fetchedOrderId);
        //console.log("PassportType from url:", passportType);

        if (!fetchedOrderId) {
          throw new Error('Order ID not found');
        }

        setOrderId(fetchedOrderId);

        const orderRef = doc(db, 'orders', fetchedOrderId);
        const orderSnap = await getDoc(orderRef);

        if (orderSnap.exists()) {
          const orderData = orderSnap.data();
          setProcessedImages([orderData.processedImages[1], orderData.processedImages[4]]);
          setPassportType(passportType || orderData.passportType);
        } else {
          throw new Error('Order not found');
        }

        setIsLoading(false);
      } catch (err) {
        console.error('Error fetching order details:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [location]);

  if (isLoading) {
    return <div>Processing your photos. This may take a few moments...</div>;
  }

  if (error) {
    return (
      <div>
        <h2>Error:</h2>
        <p>{error}</p>
        <p>URL Parameters: {window.location.search}</p>
      </div>
    );
  }

  
  const handleDownload = async () => {
    // Update order status to "downloaded"
    try {
      const orderRef = doc(db, 'orders', orderId);
      const orderDoc = await getDoc(orderRef);
      const currentStatus = orderDoc.data().status || '';
      await updateDoc(orderRef, {
        status: currentStatus ? `${currentStatus}, downloaded` : 'downloaded',
        downloadedAt: new Date()
      });
    } catch (error) {
      console.error('Error updating order status:', error);
    }

    if (!passportType) {
      console.error('Passport type is not set');
      return;
    }
    const photoRequirements = photoRequirementsDetailed[passportType];
    if (!photoRequirements) {
      console.error('Photo requirements not found for passport type:', passportType);
      return;
    }
    const { digital_dims, ImageSize, SheetSize } = photoRequirements;

    const [country, photoType] = passportType.split('-');
    const imageNames = [
      `${country.replace(/\s+/g, '')}_${photoType.replace(/\s+/g, '')}_Digital_${digital_dims}px.jpeg`,
      `${country.replace(/\s+/g, '')}_${photoType.replace(/\s+/g, '')}_PrintSize_${SheetSize.replace(/\s+/g, '')}_ImageSize_${ImageSize.replace(/\s+/g, '')}.jpeg`
    ];

    for (let index = 0; index < processedImages.length; index++) {
      const imageUrl = processedImages[index];
      try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = imageNames[index];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error(`Error downloading image ${index + 1}:`, error);
        alert(`Failed to download image ${index + 1}. Please try again.`);
      }
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-slate-950 dark:text-white p-8">
      <h1 className="text-3xl font-bold mb-4 text-slate-950 dark:text-white">Thank you for your order!</h1>
      <p className="text-base sm:text-xl mb-8 text-slate-950 dark:text-white">Photos have been emailed and can be downloaded below.</p>
      {processedImages.length > 0 ? (
        <>
          <div className="flex flex-col items-center gap-8">
            {processedImages.map((image, index) => (
              <div key={index} className="w-full flex flex-col items-center">
                <h2 className="text-lg sm:text-xl font-semibold mb-2 bg-blue-300 text-white px-4 py-1 rounded inline-block">
                  {index === 0 ? "Digital Copy" : "5x7 inch Print Sheet"}
                </h2>
                <div className="h-[25vh] flex items-center justify-center">
                  <div className="h-full relative">
                    <img
                      src={image}
                      alt={`Processed image ${index === 0 ? 'Digital' : 'Print'}`}
                      className="h-full w-auto object-contain"
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="mt-8 flex justify-center">
            <button
              onClick={handleDownload}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full sm:w-3/4 md:w-2/3 lg:w-1/2"
            >
              Download Images
            </button>
          </div>
        </>
      ) : (
        <div>Your photos are still being processed. Please check back in a few minutes.</div>
      )}
    </div>
  );
};

export default SuccessPage;