import React from "react";
import { motion } from "framer-motion";
import { IconUpload } from "@tabler/icons-react";
import { useDropzone } from "react-dropzone";

const mainVariant = {
  initial: { 
    x: 0, 
    y: 0, 
    backgroundColor: "#2563eb",  // Initial blue color
    opacity: 1
  },
  animate: { 
    x: 20, 
    y: -20, 
    backgroundColor: "#15803d",  // Green color on hover
    transition: { duration: 0.3 }
  },
};

export const FileUpload = ({ onChange }) => {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles);
      }
    },
    onDropRejected: (error) => {
      console.log(error);
    },
  });

  return (
    <div className="flex flex-col items-center w-full">
      <div className="w-[70%] mx-auto" {...getRootProps()}>
        <motion.div
          className="p-6 rounded-lg cursor-pointer w-full relative overflow-hidden border-4 border-dotted border-blue-600 bg-transparent"
          style={{ borderSpacing: '8px', backgroundClip: 'padding-box' }}
        >
          <input {...getInputProps()} />
          <p className="text-center text-blue-600 font-medium tracking-tight mb-4">
            Drop your file here or upload
          </p>
          <div className="flex justify-center">
            <motion.div
              whileHover="animate"
              initial="initial"
              className="w-32 h-32 border-2 border-dashed border-blue-600 rounded-lg flex items-center justify-center relative"
            >
              <motion.div
                variants={mainVariant}
                className="absolute w-32 h-32 rounded-lg shadow-md flex items-center justify-center"
              >
                <IconUpload className="w-10 h-10 text-white" />
              </motion.div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};