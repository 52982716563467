import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Check } from 'lucide-react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';
import { loadStripe } from '@stripe/stripe-js';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

// console.log('Stripe Public Key:', process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const PreviewPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [processedImages, setProcessedImages] = useState(null);
  const [selectedOption, setSelectedOption] = useState('Digital');
  const purchaseButtonRef = useRef(null);
  const [orderId, setOrderId] = useState(null);
  const [passportType, setPassportType] = useState("United States-Passport (2 x 2 inch)"); // or whatever default value you want
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const isValidEmail = async (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!re.test(String(email).toLowerCase())) return false;

    const [, domain] = email.split('@');
    try {
      const dnsResponse = await fetch(`https://dns.google/resolve?name=${domain}&type=MX`);
      const dnsData = await dnsResponse.json();
      return dnsData.Answer && dnsData.Answer.length > 0;
    } catch (error) {
      console.error('Error checking email domain:', error);
      return true; // If the check fails, we'll assume the email is valid
    }
  };

  useEffect(() => {
    if (location.state && location.state.processedImages) {
      const { processedImages, orderId, passportType } = location.state;
      const [country, photoType] = passportType.split('-');
      setProcessedImages([
        location.state.processedImages[3], // guidelines_physical
        location.state.processedImages[2], // guidelines_digital
        location.state.processedImages[4]  // passport_sheet
      ]);
      setOrderId(orderId);
      setPassportType(passportType);
      // console.log("PreviewPage - passportType after setting:", passportType);
    } else {
      // If there's no state, we're in preview-test mode
      // Set some dummy data for testing
      setProcessedImages([
        '/images/preview_imgs/output.2.jpg',
        '/images/preview_imgs/output.3.jpg',
        '/images/preview_imgs/output.4.jpg'
      ]);
      setOrderId('preview-test-order-id');
      setPassportType('United States-Passport (2 x 2 inch)');
    }
  }, [location.state]);

  const handlePurchase = async () => {
    if (selectedOption === 'Digital') {
      if (!email) {
        setEmailError('Please enter email to receive photos');
        return;
      }
      const isValid = await isValidEmail(email);
      if (!isValid) {
        setEmailError('Please enter a valid email address');
        return;
      }
      
      try {
        // console.log("Attempting to process digital order");
        const orderRef = doc(db, "orders", orderId);
        const orderDoc = await getDoc(orderRef);
        // console.log("Order document retrieved:", orderDoc.exists ? "exists" : "does not exist");
        const currentStatus = orderDoc.data().status || '';
        const processedImages = orderDoc.data().processedImages || [];
        // console.log("Processed images:", processedImages);

        // Update order status
        await updateDoc(orderRef, {
          status: currentStatus ? `${currentStatus}, digital download` : 'digital download',
          email: email
        });
        // console.log("Order status updated");

        // Call the sendEmailWithAttachments function
        // console.log("Calling sendEmailWithAttachments function");
        const sendEmail = httpsCallable(functions, 'sendEmailWithAttachments');
        const result = await sendEmail({
          toEmail: email,
          imageUrls: processedImages,
          passportType: passportType,
          photoOption: 'Digital'
        });
        // console.log("sendEmailWithAttachments result:", result);

        // Update order to indicate email was sent
        await updateDoc(orderRef, { emailSent: true });
        // console.log("Order updated: email sent");

        navigate('/success', { 
          state: { orderId, passportType },
          replace: true
        });
      } catch (error) {
        // console.error("Error processing digital order:", error);
        // console.error("Error details:", error.message, error.code, error.details);
        alert("An error occurred while processing your order. Please try again.");
      }
    } else {
      try {
        const createCheckoutSession = httpsCallable(functions, 'createCheckoutSession');
        if (!orderId) {
          // console.error('Order ID is missing');
          alert('An error occurred. Please try again.');
          return;
        }

        // console.log("PreviewPage - passportType before createCheckoutSession:", passportType);
        const result = await createCheckoutSession({
          amount: getSelectedPrice(),
          photoOption: selectedOption,
          requireShipping: selectedOption === 'Digital+ & Prints',
          orderId: orderId,
          passportType: passportType
        });

        const stripe = await stripePromise;
        if (result.data && result.data.sessionId) {
          const { error } = await stripe.redirectToCheckout({
            sessionId: result.data.sessionId,
          });

          if (error) {
            // console.error('Stripe Error:', error);
            alert('An error occurred during checkout. Please try again.');
          }
        } else {
          // console.error('Invalid response from createCheckoutSession:', result);
          alert('An error occurred while creating the checkout session. Please try again.');
        }
      } catch (e) {
        // console.error('Error:', e);
        alert('An error occurred while processing your purchase. Please try again.');
      }
    }
  };

  const getSelectedPrice = () => {
    switch (selectedOption) {
      case 'Digital':
        return 0; // Free
      case 'Digital+':
        return 499; // $4.99 in cents
      case 'Digital+ & Prints':
        return 1499; // $14.99 in cents
      default:
        return 0; // Default to free
    }
  };

  const scrollToPurchaseButton = () => {
    purchaseButtonRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  if (!processedImages) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-slate-950 dark:text-white p-8 flex items-center justify-center">
        <p className="text-xl">Loading preview...</p>
      </div>
    );
  }

  const options = [
    { 
      title: "Digital", 
      price: "Free", 
      features: ["Instant Delivery", "Digital Photo & Printable Photo Sheet"], 
      button: "Download", 
      extraContent: (
        <p className="text-gray-500 text-sm mt-2">
          You can print at
          <img src="/images/passport_imgs/walgreens.png" alt="Walgreens" className="inline-block h-3.5 md:h-4 mx-1" />
          <img src="/images/passport_imgs/cvs.png" alt="CVS" className="inline-block h-3.5 md:h-4 mx-1" />
          <img src="/images/passport_imgs/walmart.png" alt="Walmart" className="inline-block h-3.5 md:h-4 mx-1" />
        </p>
      ) 
    },
    { 
      title: "Digital+", 
      price: "$4.99", 
      features: ["Everything in Digital option", "Expert Review", "Money back guarantee"], 
      button: "Get Digital+" 
    },
    { 
      title: "Digital+ & Prints", 
      price: "$14.99", 
      features: ["Everything in Digital+ Option", "Free Shipping"], 
      button: "Get All",
      extraContent: (
        <p className="text-gray-500 text-sm mt-2">
          Estimated Delivery in 1-5 business days
          <img src="/images/passport_imgs/usps.png" alt="USPS" className="inline-block h-4 ml-1" />
        </p>
      )
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-100 to-white dark:from-gray-900 dark:to-gray-800 text-slate-950 dark:text-white p-4 sm:p-6 md:p-8">
      <div className="flex flex-col lg:flex-row justify-start items-start lg:items-start lg:h-[90vh]">
        {/* Left Container */}
        <div className="w-full lg:w-[45%] h-full lg:h-[90vh] flex flex-col justify-start items-center mb-8 lg:mb-0 pt-4 lg:pt-8">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-4 text-center">Photo Preview</h1>
          <button
            onClick={scrollToPurchaseButton}
            className="lg:hidden w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded mb-4 text-xl"
          >
            Purchase
          </button>
          
          {/* First Image Row */}
          <div className="w-full h-[40%] bg-blue-50 dark:bg-blue-900 rounded-lg mb-4 flex justify-center items-top">
            <div className="w-1/2 h-full flex flex-col justify-center items-top">
              <p className="font-bold text-lg px-4 py-2 text-center">Digital</p>
                <img
                  src={processedImages[0]}
                  alt="Digital"
                  className="w-auto h-[80%] object-contain"
                />
            </div>
            <div className="w-1/2 h-full flex flex-col justify-center items-top">
              <p className="font-bold text-lg px-4 py-2 text-center">Physical</p>

                <img
                  src={processedImages[1]}
                  alt="Physical"
                  className="w-auto h-[80%] object-contain"
                />
              
            </div>
          </div>
          
          {/* Second Image Row */}
          <div className="w-full h-[50%] bg-blue-50 dark:bg-blue-900 rounded-lg flex flex-col justify-center items-left relative">
            <p className="font-bold text-lg px-4 py-2 text-center">Printable Sheet</p>
            <img
              src={processedImages[2]}
              alt="Printable Sheet"
              className="w-auto h-[80%] object-contain"
            />
            <div className="absolute inset-0 bg-gray-500 opacity-50 rounded-lg"></div>
          </div>
        </div>

        {/* Right Container */}
        <div className="w-full lg:w-[55%] h-auto lg:h-[90vh] flex flex-col justify-start items-center lg:items-center pt-2 lg:pt-8 overflow-y-auto">
          <div className="w-full h-full flex flex-col items-center justify-start">
            {/* Email input box */}
            {selectedOption === 'Digital' && (
              <div className="w-full lg:w-[70%] mb-4">
                <div className="w-full relative">
                  <div className="absolute -top-3 left-3 px-1 bg-white">
                    <span className="text-sm text-gray-600">Email Required</span>
                  </div>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError('');
                    }}
                    className={`w-full p-2 pl-3 border-2 ${
                      emailError ? 'border-red-500' : 'border-blue-600'
                    } rounded bg-white focus:outline-none focus:ring-2 focus:ring-blue-500`}
                  />
                  {emailError && (
                    <p className="mt-2 text-sm text-red-600">{emailError}</p>
                  )}
                </div>
              </div>
            )}

            {/* Options */}
            {options.map((option, index) => (
              <div
                key={index}
                className="w-full lg:w-[70%] mb-2 lg:mb-3 flex justify-center items-center mx-auto"
              >
                <div
                  className={`w-full h-full p-3 lg:p-4 rounded-lg cursor-pointer border-2 bg-white ${
                    selectedOption === option.title ? 'border-blue-600' : 'border-gray-300'
                  } flex flex-col justify-between`}
                  onClick={() => {
                    setSelectedOption(option.title);
                    if (option.title !== 'Digital') {
                      setEmail('');
                      setEmailError('');
                    }
                  }}
                >
                  <div>
                    <div className="flex items-center mb-1 lg:mb-2">
                      <div className={`w-5 h-5 lg:w-6 lg:h-6 rounded-full ${selectedOption === option.title ? 'bg-blue-600' : 'bg-gray-300'} mr-2`}></div>
                      <h3 className="text-lg lg:text-xl font-bold text-gray-800">{option.title}</h3>
                    </div>
                    <p className="text-xl lg:text-2xl font-bold mb-1 lg:mb-2 text-gray-800">{option.price}</p>
                  </div>
                  <ul className="text-xs lg:text-sm text-gray-600 overflow-y-auto flex-grow">
                    {option.features.map((feature, i) => (
                      <li key={i} className="flex items-center mb-1">
                        <Check className="flex-shrink-0 mr-1 h-3 w-3 lg:h-4 lg:w-4 text-green-500" />
                        <span className="overflow-hidden text-ellipsis">{feature}</span>
                      </li>
                    ))}
                  </ul>
                  {option.extraContent && <div className="mt-1 lg:mt-2">{option.extraContent}</div>}
                </div>
              </div>
            ))}

            {/* Purchase button */}
            <div className="w-full lg:w-[70%] flex flex-col justify-center items-center mt-2 lg:mt-3 mx-auto">
              <button
                ref={purchaseButtonRef}
                onClick={handlePurchase}
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 lg:py-3 px-4 rounded text-xl lg:text-2xl"
              >
                {selectedOption === 'Digital' ? 'Download' : 'Purchase'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewPage;