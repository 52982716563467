import React, { useState } from 'react';

const images = [
  '/images/female_indoor.png',
  '/images/baby.png',
  '/images/female_brick.png',
  '/images/male_outdoor.png',
];

const ImageGrid = ({ selectedImage, onImageClick }) => {
  const [hoveredImage, setHoveredImage] = useState(null);

  const getGrayScaleImage = (imagePath) => {
    return imagePath.replace('.png', '_o.jpg');
  };

  const blurryBorderStyle = {
    position: 'absolute',
    top: '-6px',
    left: '-6px',
    right: '-6px',
    bottom: '-6px',
    border: '6px solid rgba(165, 180, 252, 0.7)', // Changed to indigo-300 with opacity
    filter: 'blur(8px)',
    zIndex: 1,
  };

  const tinyImageBlurryBorderStyle = {
    ...blurryBorderStyle,
    border: '6px solid rgba(165, 180, 252, 0.4)', // Changed to indigo-300 with lower opacity
  };

  const circleStyle = {
    position: 'absolute',
    top: '7.5%',  // Adjusted from 5% to 7.5%
    left: '7.5%', // Adjusted from 5% to 7.5%
    width: '7%',  // Reduced from 10% to 7%
    height: '7%', // Reduced from 10% to 7%
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    zIndex: 3,
  };

  const dotStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',  // Increased from 60% to 80%
    height: '80%', // Increased from 60% to 80%
    borderRadius: '50%',
    backgroundColor: '#2563eb', // Changed to dark blue (Tailwind's blue-600)
    zIndex: 4,
  };

  return (
    <div className="flex w-full h-full">
      <div className="w-1/2 pr-2 aspect-square">
        <div className="grid grid-cols-2 gap-2">
          {images.map((src, index) => (
            <div 
              key={index} 
              className="relative aspect-square"
              onMouseEnter={() => setHoveredImage(index)}
              onMouseLeave={() => setHoveredImage(null)}
              onClick={() => onImageClick(index)}
            >
              <img
                src={src}
                alt={`Thumbnail ${index + 1}`}
                className="cursor-pointer absolute inset-0 w-full h-full object-cover"
                style={{ zIndex: 2 }}
                onError={(e) => {
                  console.error(`Error loading image: ${src}`);
                  e.target.src = 'https://via.placeholder.com/49';
                }}
              />
              {(hoveredImage === index || (hoveredImage === null && index === 0)) && <div style={blurryBorderStyle}></div>}
              <div style={circleStyle}>
                {(hoveredImage === index || (hoveredImage === null && index === 0)) && <div style={dotStyle}></div>}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-1/2 pl-2 flex flex-col">
        <div className="aspect-square w-full relative">
          <img
            src={getGrayScaleImage(images[hoveredImage !== null ? hoveredImage : selectedImage])}
            alt="Selected image"
            className="absolute inset-0 w-full h-full object-cover"
            style={{ zIndex: 2 }}
            onError={(e) => {
              console.error(`Error loading selected image: ${getGrayScaleImage(images[hoveredImage !== null ? hoveredImage : selectedImage])}`);
              e.target.src = 'https://via.placeholder.com/98';
            }}
          />
          <div style={blurryBorderStyle}></div>
          {/* Tiny tilted images */}
          <div className="absolute bottom-[-5%] right-[-2.5%] w-[18%] aspect-[1/2]" style={{transform: 'rotate(5deg)', zIndex: 3}}>
            <div className="relative w-full h-[48%] mb-[4%]">
              <img
                src={getGrayScaleImage(images[hoveredImage !== null ? hoveredImage : selectedImage])}
                alt="Tiny image 1"
                className="absolute inset-0 w-full h-full object-cover"
                style={{ zIndex: 2 }}
              />
              <div style={tinyImageBlurryBorderStyle}></div>
            </div>
            <div className="relative w-full h-[48%]">
              <img
                src={getGrayScaleImage(images[hoveredImage !== null ? hoveredImage : selectedImage])}
                alt="Tiny image 2"
                className="absolute inset-0 w-full h-full object-cover"
                style={{ zIndex: 2 }}
              />
              <div style={tinyImageBlurryBorderStyle}></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageGrid;