"use client";
import React from "react";
import { motion } from "framer-motion";
import { cn } from "../../lib/utils";

export default function LampDemo() {
  return (
    <LampContainer>
      {/* Lamp effect content */}
    </LampContainer>
  );
}

export const LampContainer = ({
  children,
  className
}) => {
  return (
    <div
      className={cn(
        "relative flex flex-col items-center justify-start overflow-hidden w-full h-full",
        className
      )}
    >
      <div className="relative flex w-full h-full items-center justify-center isolate z-0">
        <motion.div
          initial={{ opacity: 0.5, width: "30%" }}
          whileInView={{ opacity: 1, width: "60%" }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          style={{
            backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
          }}
          className="absolute inset-auto right-1/2 h-full overflow-visible bg-gradient-conic from-indigo-300 via-transparent to-transparent text-white [--conic-position:from_70deg_at_center_top]"
        >
          <div className="absolute w-full left-0 bg-transparent h-1/2 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
          <div className="absolute w-1/4 h-full left-0 bg-transparent bottom-0 z-20 [mask-image:linear-gradient(to_right,white,transparent)]" />
        </motion.div>
        <motion.div
          initial={{ opacity: 0.5, width: "30%" }}
          whileInView={{ opacity: 1, width: "60%" }}
          transition={{
            delay: 0.3,
            duration: 0.8,
            ease: "easeInOut",
          }}
          style={{
            backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
          }}
          className="absolute inset-auto left-1/2 h-full bg-gradient-conic from-transparent via-transparent to-indigo-300 text-white [--conic-position:from_290deg_at_center_top]"
        >
          <div className="absolute w-1/4 h-full right-0 bg-transparent bottom-0 z-20 [mask-image:linear-gradient(to_left,white,transparent)]" />
          <div className="absolute w-full right-0 bg-transparent h-1/2 bottom-0 z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
        </motion.div>
      </div>
      <div className="relative z-50 flex flex-col items-center px-5">
        {children}
      </div>
    </div>
  );
};